import React from 'react';
import './Login.css';

export default function Login() {
  return(
    <div className="login-wrapper">
      <h1>Anmeldung</h1>
      <form>
        <label>
          <p>Ihre EMail Adresse</p>
          <input type="text" />
        </label>
        <label>
          <p>Ihr Password</p>
          <input type="password" />
        </label>
        <div>
          <button type="submit">Anmelden</button>
        </div>
        <div>Sie haben Ihr Passwort vergessen?</div>
        <div>Noch kein Konto? <a href="/register">Registrieren</a></div>
      </form>
    </div>
  )
}