/* 
import './App.css';
import SearchInput from './components/SearchInput';



function App() {
 
  return (
    <div className="App">
      <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
      <title>Automatisierer.at</title>
      <header className="App-header">
      <SearchInput />
      </header>
      
    </div>
  );
}

export default App; */
import React, { useState }  from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import Preferences from './components/Preferences/Preferences';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import SearchInput from './components/SearchInput';
function App() {
  const [token, setToken] = useState();

 /* if(!token) {
    return <Login setToken={setToken} />
  }*/
  return (
    <div className="wrapper">
      <h1>-</h1>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SearchInput />} />
          <Route path="/dashboard" element={<Dashboard />} />  
          <Route path="/preferences" element= {<Preferences />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;