import './Register.css'

import React, { useState } from 'react';
//import Card from 'react-bootstrap/Card';
//import Navigation from '../globals/navbar';
import AuthService from '../../services/authservice';
import { RiMentalHealthLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';


function Register() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
 // const [token, setToken] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();  
  const handleSignin = async () => {
    try {
      await AuthService.signinUser(firstname, lastname, email, password).then(() => {
        setMessage("Registrierung erfolgreich");
        //navigate("/login");
        //window.location.reload();
      },
      (error) => {
        const err = error.response.data;
        console.log("ERROR", err.response);
        setMessage(err.response);
      });

    } catch ( error) { 
        console.log("CATCH",error);
      } 
};      
  

  

  return (

    <>
      <div className="container mt-5 pt-5" >
        <div className='col-12 col-sm-8 col-md-6 mx-auto' >
          <div className='mb-3'>
            <h1>Registrierung</h1>
          </div>
          <div className='card'>
            <div className='card-body shadow'>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
              </svg>
              <div>
                <input
                  type="text"
                  placeholder="Vorname"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  className='form-control mt-3 py-2'
                />
                 <input
                  type="text"
                  placeholder="Nachname"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  className='form-control mt-3 py-2'
                />
                <br />
                <input
                  type="text"
                  placeholder="E-Mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className='form-control py-2'
                />
                <br />
                <input
                  type="password"
                  placeholder="Passwort"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className='form-control py-2'
                />

                <br />
                <button class="btn btn-dark my-3 px-5" onClick={handleSignin}>Registrieren</button>
                <p>{message}</p>

              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
} export default Register