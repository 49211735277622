import axios from 'axios';
import { jwtDecode } from "jwt-decode";

//const USER_API_BASE_URL = "http://localhost:8888";
const USER_API_BASE_URL = "https://automatisierer.at/questionrest";

class AuthService {
  
  getToken()
  {
    const access_token = localStorage.getItem('token');
    return { Authorization: `Bearer ${access_token}` };
  }
  
  getTokenHeader()
  {
    //const access_token = localStorage.getItem('token');
    return { headers: this.getToken() };
  }


    getCurrentToken = () => { 
      return localStorage.getItem('token');
    }; 

    getCurrentTokenId = () => { 
      return localStorage.getItem('tokenid');
    };

    getCurrentTokenRole = () => {
      return localStorage.getItem('tokenrole');
    };

    isCurrentRoleAdmin = () => {
      return localStorage.getItem('tokenrole') === 'ADMIN';
    };

    isCurrentRoleGuest = () => {  
      
      return !this.isCurrentRoleAdmin();
    };

    logout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenid');
      localStorage.removeItem('tokenrole');
    };



    loginUser(email, password)
    {
        return axios.post(USER_API_BASE_URL + '/rest/auth/login', {
        email: email,
        password: password
      }).then((response) => {
        if (response.data.success === true)
        {
          let decoded = jwtDecode(response.data.response);
          localStorage.setItem('token', response.data.response);
          localStorage.setItem('tokenid', decoded.id);
          localStorage.setItem('tokenrole', decoded.role);
        
        }
      return response.data;
      });
        
   
    }  


    signinUser(firstname, lastname, email, password)
    {
      return axios.post(USER_API_BASE_URL + '/auth/register', {
        firstname: firstname,
        lastname: lastname,
        email: email,
        password: password
      }).then((response) => {
        if (response.data.success === true)
        {
          localStorage.setItem('token', response.data.response);
        }
      return response.data;
    });
  }

   updateUser(user, userId){
      return  fetch(USER_API_BASE_URL + '/update/' + userId, {
       method: 'POST', // *GET, POST, PUT, DELETE, etc.
       /*mode: 'no-cors', // no-cors, *cors, same-origin
       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       credentials: 'same-origin', // include, *same-origin, omit
       headers: {
         'Content-Type': 'application/json'
         // 'Content-Type': 'application/x-www-form-urlencoded',
       },
       redirect: 'follow', // manual, *follow, error
       referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url */
       body: JSON.stringify(user) // body data type must match "Content-Type" header
     });

   }

    deleteUser(userId){
        return axios.delete(USER_API_BASE_URL + '/delete/' + userId);
        //return  fetch(USER_API_BASE_URL + '/delete/' + userId, {
          //method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
          //mode: 'no-cors', // no-cors, *cors, same-origin
          //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: 'same-origin', // include, *same-origin, omit
          //headers: {
          //  'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          //},
          //redirect: 'follow', // manual, *follow, error
          //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          //body: JSON.stringify('') // body data type must match "Content-Type" header
        //});
    }

    uploadUser(file, id){
        let fd = new FormData();
        fd.append('image[]', file, file.name);
        fd.append('userid', id);
        console.log('UserId', id)
        return axios.post("http://localhost:8888/rest/upload", fd, this.getTokenHeader())
        .then(res => {
          console.log('RES',res);
        });
    }

    upload(file, onUploadProgress)
    {
      let formData = new FormData();

      formData.append("file", file, this.getTokenHeader());
      
    }
    
    
    
    /*uploadUser1 = (file) => {
      
      

      //let files = this.$resf.file.files;
let formData = new FormData();
//files.forEach( (el) => {
  formData.append('images[]', file); 
//});
      fetch('http://localhost:8888/rest/upload',{ 
        method: 'POST',
        //mode: 'no-cors', // no-cors, *cors, same-origin
        //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: 'same-origin', // include, *same-origin, omit
        //redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: formData,
        headers: {
          
        //  'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
          'Content-Type': 'multipart/form-data'
        },
      }
        ).then(
          response => response.json() // if the response is a JSON object
        ).then(
          success => console.log(success) // Handle the success response object
        ).catch(
          error => console.log(error) // Handle the error response object
        )
      } */

      uploadUser2(file) {
        fetch('http://localhost:8888/rest/upload', { // Your POST endpoint
          method: 'POST',
          //mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
            'Origin': '*'
          },
          body: file // This is your file object
        }).then(
          response => response.json() // if the response is a JSON object
        ).then(
          success => console.log(success) // Handle the success response object
        ).catch(
          error => console.log(error) // Handle the error response object
        );
      }
}

const authservice = new AuthService()
export default authservice;
