import React, { useState } from 'react';
import axios from 'axios';





function SearchInput() {

    const [result, setResult] = useState('');

    function handleKeyPress(event) 
{
 if(event.key === 'Enter'){
  axios.get(`https://automatisierer.at/questionrest/v1/search/` + event.target.value)
  .then(res => {
    event.target.value = '';
    //const result = res.data;
    //setResult({ result });
  })

 }
}
  return (
    <div className="SearchInput">
      <input type="text" placeholder='automation plans'  onKeyPress={handleKeyPress} />
    </div>
  );
}

export default SearchInput;